// 	Configuration, fonts, colors, and mixins.

// Fonts
$mainFont: "Montserrat", sans-serif;
$helvetica: Helvetica, sans-serif;

@import "colors";

// Animations
// Animation to fade items in
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Animation to fade items out
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

// Styles
html {
	font-size: 14px;
}
