// 	Modals

.modal {
	.modal-header {
		border-bottom: 0;
		&.bigger-header {
			align-items: start;
			padding-bottom: 0;
		}
		a {
			&.close-btn {
				font-size: 1.5rem;
				line-height: 0;
				&:link,
				&:visited,
				&:active,
				&:focus {
					color: $dark-blue03;
					text-decoration: none;
				}
				&:hover {
					color: $light-blue02;
					text-decoration: none;
				}
			}
		}
	}
	.modal-footer {
		border-top: 0;
	}
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
}

//  Styles for the large breakpoint.
@media (min-width: 992px) {
}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {
}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
}
