// google fonts
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700&display=swap");

// calendly
@import url("https://assets.calendly.com/assets/external/widget.css");

// bootstrap
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// bootstrap and overrides
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "./bootstrap-config";
@import "bootstrap/scss/bootstrap";

// base configurations
@import "config", "mixins", "forms", "layout", "modal";

// overrides
@import "offcanvas";
