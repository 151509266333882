// Colors
$white: #ffffff;
$off-white01: #fafafa;
$off-white02: #f2f2f2;
$light-gray: #ebebeb;
$black: #000000;
$pk-blue: #1a2574;
$dark-blue01: #1f2d8c;
$dark-blue02: #0e1440;
$dark-blue03: #214a86;
$blue-gray01: #739bb3;
$blue-gray02: #4a6473;
$light-blue01: #12a5ff;
$light-blue02: #1e91d6;
$light-blue03: #1097eb;
$dark-gray01: #707070;
$dark-gray02: #373c40;
$dark-gray03: #6c757d;
$faint-blue01: #c4e8ff;
$faint-blue02: #b2c0d5;
$half-blue: #e4e9f0;
$orange: #fc8f3b;
$green: #009900;
$red: #ff0000;
$pink: #fff1f1;
