// 	General Layout Elements

body {
	font-family: $mainFont;
	font-weight: 500;
	font-size: 16px;
	color: $dark-gray01;
	overflow-y: scroll;
}

h1 {
	font-family: $mainFont;
	font-weight: 600;
	color: $dark-blue03;
	font-size: 2rem;
	&.add-upperline {
		border-top: 2px solid $dark-blue03;
		padding-top: 1.25rem;
		display: inline-block;
		&.orange-line {
			border-top: 2px solid $orange;
		}
		&.lt-blue-line {
			border-top: 2px solid $light-blue02;
		}
	}
	.upper-sub-headline {
		font-size: 1.25rem;
		line-height: 1rem;
	}
}

h2 {
	font-family: $mainFont;
	font-weight: 600;
	color: $dark-blue03;
	font-size: 1.5rem;
	&.add-upperline {
		border-top: 2px solid $dark-blue03;
		padding-top: 1.25rem;
		display: inline-block;
		&.orange-line {
			border-top: 2px solid $orange;
		}
		&.lt-blue-line {
			border-top: 2px solid $light-blue02;
		}
	}
}

h3 {
	font-family: $mainFont;
	font-weight: 600;
	color: $dark-blue03;
	font-size: 1.25rem;
	&.add-upperline {
		border-top: 2px solid $dark-blue03;
		padding-top: 1.25rem;
		display: inline-block;
		&.orange-line {
			border-top: 2px solid $orange;
		}
		&.lt-blue-line {
			border-top: 2px solid $light-blue02;
		}
	}
}

h4 {
	font-family: $mainFont;
	font-weight: 600;
	color: $dark-blue03;
	font-size: 1rem;
	&.add-upperline {
		border-top: 2px solid $dark-blue03;
		padding-top: 1.25rem;
		display: inline-block;
		&.orange-line {
			border-top: 2px solid $orange;
		}
		&.lt-blue-line {
			border-top: 2px solid $light-blue02;
		}
	}
}

p {
	&.add-line-height {
		line-height: 1.75rem;
	}
}

ul {
	&.bullet-feature {
		list-style: none;
		li {
			font-size: 1rem;
			font-weight: 600;
			margin-bottom: 1rem;
			&:before {
				font-family: "FontAwesome";
				font-size: 0.75rem;
				content: "\f111";
				margin: 0 15px 0 -20px;
				color: $dark-blue01;
			}
		}
	}
}

a {
	&:link,
	&:visited,
	&:active {
		color: $light-blue01;
	}
	&:hover,
	&:focus {
		color: $dark-blue03;
		text-decoration: none;
	}
	&.light-link {
		&:link,
		&:visited,
		&:active {
			color: $faint-blue01;
		}
		&:hover,
		&:focus {
			color: $faint-blue02;
			text-decoration: none;
		}
	}
}

// Font Sizing
.font-size-075 {
	font-size: 0.75rem !important;
}
.font-size-085 {
	font-size: 0.85rem !important;
}
.font-size-095 {
	font-size: 0.95rem !important;
}
.font-size-1 {
	font-size: 1rem !important;
}
.font-size-125 {
	font-size: 1.25rem !important;
}
.font-size-15 {
	font-size: 1.5rem !important;
}
.font-size-175 {
	font-size: 1.75rem !important;
}
.font-size-2 {
	font-size: 2rem !important;
}

// Font Weights
.medium-text {
	font-weight: 500 !important;
}

.semibold-text {
	font-weight: 600 !important;
}

strong,
.bold-text {
	font-weight: 700 !important;
}

// Font Colors
.white-text {
	color: $white !important;
}

.black-text {
	color: $black !important;
}

.pk-blue-text {
	color: $pk-blue !important;
}

.lt-blue-text {
	color: $light-blue01 !important;
}

.orange-text {
	color: $orange !important;
}

.green-text {
	color: $green !important;
}

.red-text {
	color: $red !important;
}

// Background Colors
.light-gray-bg {
	background-color: $light-gray !important;
}

.pk-blue-bg {
	background-color: $pk-blue !important;
}

// Image Enhancements
.drop-shadow {
	filter: drop-shadow(0 0.2rem 0.4rem rgba(0, 0, 0, 0.25));
}

// Cards with Icons at the Top
.icon-card {
	border: none;
	border-radius: 0;
	background-color: unset;
	.card-body {
		background-color: $off-white02;
		.card-icon {
			max-width: 75px;
		}
		h5 {
			color: $dark-blue03;
			font-weight: 600;
		}
		&.white-bg {
			background-color: $white;
		}
	}
	.card-footer {
		padding: 0;
		background-color: unset;
		border-top: none;
		border-radius: 0;
	}
}

// Allows for elements to only hit a certain maximum width
// .width-limit will one day be retired and removed.
.maxwidth-800 {
	max-width: 800px;
}
.maxwidth-960 {
	max-width: 960px;
}
.maxwidth-1080 {
	max-width: 1080px;
}
.maxwidth-1200 {
	max-width: 1200px;
}
.maxwidth-1264 {
	max-width: 1264px;
}
.maxwidth-1440 {
	max-width: 1440px;
}

// CTA Plan Card
.cta-plan-card {
	color: $white;
	background-image: url("https://assets.powerkiosk.com/web/images/plan-card-bg.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	font-size: 1rem;
	.card-content {
		padding: 2rem 2rem 1rem 2rem;
		h4 {
			color: $white;
			font-size: 1.75rem;
			font-weight: 500;
		}
		.percentile-num {
			font-weight: bold;
			font-size: 5.5rem;
			line-height: 5.5rem;
			sup {
				font-size: 3rem;
			}
		}
	}
}

// Tabbed Areas
.nav-tabs {
	border-bottom: 2px solid $pk-blue;
	justify-content: center;
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			border: 0;
			font-weight: 600;
			color: $light-blue01;
			&:link,
			&:visited {
				color: $light-blue01;
			}
			&:hover,
			&:focus {
				color: $dark-blue03;
				text-decoration: none;
			}
			&:active,
			&.active {
				color: $pk-blue;
				font-weight: 700;
				border-bottom: 2px solid $pk-blue;
			}
		}
	}
}

// Fade In Animation
@for $i from 1 through 8 {
	.section-#{$i} {
		opacity: 0;
		animation: fade-in 0.5s ease-out #{$i * 0.25}s forwards;
	}
}

//  Styles for the small breakpoint.
@media (min-width: 576px) {
}

//  Styles for the medium breakpoint.
@media (min-width: 768px) {
}

//  Styles for the large breakpoint.
@media (min-width: 992px) {
	h1 {
		font-size: 2.4rem;
		line-height: 3rem;
	}

	h2 {
		font-size: 1.8rem;
		line-height: 2.25rem;
	}

	h3 {
		font-size: 1.35rem;
		line-height: 1.75rem;
	}

	h4 {
		font-size: 1.15rem;
		line-height: 1.4rem;
	}

	.shift-up {
		top: -5rem;
	}
}

//  Styles for the extra-large breakpoint.
@media (min-width: 1200px) {
}

//  Styles for the extra-extra-large breakpoint.
@media (min-width: 1440px) {
}

//  Styles for the extra-extra-extra-large breakpoint.
@media (min-width: 1920px) {
}
