// 	Form elements

.form-control {
	border: 1px solid $half-blue;
	font-size: 0.9rem;
	background: $half-blue;
	color: $dark-blue03;
	padding: 0.6em 1em;
	height: auto;
	&:focus,
	&:active {
		box-shadow: none;
		border: 1px solid $half-blue;
		background: $off-white01;
		color: $dark-blue03;
	}
	&.invalid {
		border: 1px solid $red;
		background: $pink;
		color: $red;
		&:focus,
		&:active {
			border: 1px solid $red;
			background: $off-white01;
			color: $dark-blue03;
		}
	}
}

.form-label,
.form-check-label {
	color: $black;
	font-weight: 500;
	font-size: 1em;
	margin: 0 0 0.5em 0;
	&.invalid {
		color: $red;
		font-weight: 600;
	}
}

// Radio Buttons
.form-check {
	.form-check-input {
		border-color: $faint-blue02;
		background-color: $half-blue;
		&:checked {
			border-color: $pk-blue;
			background-color: $pk-blue;
		}
		&:focus,
		&:active {
			box-shadow: none;
		}
	}
}

.btn {
	text-transform: uppercase;
	font-family: $mainFont;
	font-weight: 700;
	&:hover {
		transition: all 0.15s ease-in-out;
	}
	&:focus,
	&:active:focus {
		box-shadow: none;
	}
	&.btn-primary {
		color: $white !important;
		background-color: $dark-blue01 !important;
		border-color: $dark-blue01 !important;
		&:hover {
			background-color: $dark-blue02 !important;
			border-color: $dark-blue02 !important;
		}
	}
	&.btn-secondary {
		color: $white !important;
		background-color: $light-blue03 !important;
		border-color: $light-blue03 !important;
		&:hover {
			background-color: $dark-blue01 !important;
			border-color: $dark-blue01 !important;
		}
	}
	&.btn-light {
		color: $dark-blue03 !important;
		background-color: $white !important;
		border-color: $white !important;
		&:hover {
			color: $white !important;
			background-color: $dark-blue01 !important;
			border-color: $dark-blue01 !important;
		}
	}
	&.btn-light-alt {
		color: $light-blue03 !important;
		background-color: $white !important;
		border-color: $white !important;
		&:hover {
			color: $white !important;
			background-color: $dark-blue01 !important;
			border-color: $dark-blue01 !important;
		}
	}
	&.btn-outline-primary {
		color: $white !important;
		border-color: $white !important;
		&:hover {
			color: $dark-blue03 !important;
			background-color: $white !important;
			border-color: $white !important;
		}
	}
	&.btn-outline-secondary {
		color: $light-blue03 !important;
		border-color: $light-blue03 !important;
		&:hover {
			color: $white !important;
			background-color: $dark-blue01 !important;
			border-color: $dark-blue01 !important;
		}
	}
	&.btn-form-field {
		color: $dark-blue03 !important;
		background-color: $half-blue !important;
		border-color: $half-blue !important;
		font-weight: 500 !important;
		&:hover {
			background-color: $half-blue !important;
			border-color: $half-blue !important;
		}
	}
}

// Adding a space between buttons in button group.
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	margin-right: 1rem;
}

// Invalid for Input Group
.input-group {
	&.invalid {
		.form-control {
			border: 1px solid $red;
			background: $pink;
			color: $red;
			&:focus,
			&:active {
				border: 1px solid $red;
				background: $off-white01;
				color: $dark-blue03;
			}
		}
		.btn-form-field {
			color: $red !important;
			background-color: $pink !important;
			border-color: $red !important;
			font-weight: 500 !important;
			&:hover {
				background-color: $pink !important;
				border-color: $red !important;
			}
			&.left-side-btn {
				border-right: none;
			}
			&.right-side-btn {
				border-left: none;
			}
		}
	}
}

// Dropdowns
.dropdown-menu {
	font-size: 1rem;
	border: 1px solid $faint-blue02;
	border-radius: 0.5rem;
	-webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
	-moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
	box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
	.dropdown-item {
		padding: 0.25rem 1rem;
		&:link,
		&:visited,
		&:active {
			color: $dark-gray01;
			background-color: $white;
			&.selected-item {
				font-weight: 600;
				color: $dark-gray01;
				background-color: $light-gray;
				.set-default-link {
					color: $light-blue01;
					margin-left: 0.5rem;
				}
			}
		}
		&:hover,
		&:focus {
			background-color: $half-blue;
			&.selected-item {
				.set-default-link {
					color: $pk-blue;
				}
			}
		}
	}
	&.scroll-dropdown {
		padding: 1rem;
		min-width: 18rem;
		.scrolling-area {
			max-height: 20rem;
			overflow-y: scroll;
		}
	}
}
